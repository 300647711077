@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700;1,800&family=Sora:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://use.typekit.net/vzv5bwl.css");
@tailwind base;
@tailwind components;
@tailwind utilities;

a {
  color: rgb(2, 165, 126);
}

.tooltip-custom {
  opacity: 1 !important;
  border: 1px solid black !important;
  border-radius: 5px !important;
}

.react-datepicker__input-container input {
  @apply block w-full z-50 text-base md:text-sm bg-white border-none rounded-md shadow  form-input focus:outline-none focus:ring-1 focus:ring-offset-0 focus:ring-green-500;
}

.react-datepicker-popper {
  @apply z-50 w-72 text-sm bg-white shadow-lg border border-gray-100 px-3 py-2 mt-4 rounded-md;
  z-index: 999999 !important;
}

.react-datepicker-left {
  @apply absolute left-0 right-auto top-11 transform-none !important;
}

.react-datepicker-right {
  @apply absolute right-0 left-auto top-11 transform-none !important;
}

.react-datepicker__portal {
  @apply absolute z-10 w-72 text-sm transform-none bg-white shadow px-3 py-2 top-12 right-0 border-2 border-gray-200 rounded;
  z-index: 999999 !important;
}

.react-datepicker__month-container {
  @apply flex flex-col;
}

.react-datepicker__month {
  @apply flex flex-col;
}

.react-datepicker__current-month {
  @apply ml-2.5 text-lg font-semibold text-gray-800;
}

.react-datepicker__week {
  @apply flex justify-around;
}

.react-datepicker__day-names {
  @apply flex justify-around text-gray-400 font-medium text-center text-xs;
}

.react-datepicker__day-name {
  @apply w-8 h-8 flex items-center justify-center py-1 rounded-full;
}

.react-datepicker__navigation {
  @apply absolute top-2;
}

.react-datepicker__navigation--previous {
  @apply right-12 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200;
}

.react-datepicker__navigation--next {
  @apply right-4 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200;
}

.react-datepicker__day {
  @apply mb-1 w-8 h-8 flex items-center justify-center py-1 text-sm leading-loose transition text-gray-700 rounded;
}

.react-datepicker__day--disabled {
  @apply cursor-not-allowed opacity-40 hover:bg-transparent;
}

.react-datepicker__day--outside-month {
  @apply text-gray-300;
}

.react-datepicker__day--in-range {
  @apply bg-gray-200;
}

.react-datepicker__day--in-selecting-range {
  @apply bg-green-200;
}

.react-datepicker__day--selecting-range-start {
  @apply bg-white border-2 border-green-500;
}

.react-datepicker__day--selecting-range-end {
  @apply bg-white border-2 border-green-500;
}

.react-datepicker__day--selected {
  @apply bg-green-500 text-white;
}

.react-datepicker__day--range-start {
  @apply bg-green-500 text-white hover:text-gray-700 hover:bg-white;
}

.react-datepicker__day--range-end {
  @apply bg-green-500 text-white hover:text-gray-700 hover:bg-white;
}

.react-datepicker__aria-live {
  display: none;
}

/* React Filepond styles */

.filepond--root {
  font-family: "Rubik", sans-serif !important;
}
